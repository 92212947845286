@import "../../scss/variables";
@import '../../scss/functions';
@import '../../scss/mixins/direction';

.card-faq {
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #475f7b;
  overflow: hidden;
}

.rc-accordion-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  overflow: hidden;
}

.rc-accordion-card:last-child {
  margin-bottom: 0;
}

.rc-accordion-toggle {
  //display: flex;
  align-items: center;
  cursor: pointer;
  //justify-content: space-between;
  background-color: #ebedf0;
  transition: 0.3s;
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr 20px;
}

.rc-accordion-toggle.active .rc-accordion-icon {
  color: #fff;
}

.rc-accordion-toggle.active .rc-accordion-title {
  color: black;
}

.rc-accordion-title {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  margin-bottom: 0;
  color: black;
  transition: 0.3s;
  width: calc(100% - 25px);
}

.active .rc-accordion-icon::after {
  content: "";
  color: #475F7B;
  transition: 0.35s;
  font-size: 12px;
  width: 20px;
  top: 0;
  height: 2px;
  position: absolute;
  visibility: hidden;
  background-color: black;
  transform: rotate(0);
}

.rc-accordion-icon::after {
  content: "";
  color: #475F7B;
  transition: 0.35s;
  font-size: 12px;
  width: 20px;
  //width: 24px;
  height: 2px;
  position: absolute;
  visibility: visible;
  background-color: black;
  transform: rotate(90deg);
  top: 0;
}

.rc-accordion-icon {
  position: relative;
  color: #475F7B;
  transition: 0.35s;
  font-size: 12px;
  width: 20px;
  height: 2px;
  text-align: justify !important;
  background-color: black;

  justify-self: end;
}

.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.rc-collapse.show {
  height: auto;
}

.rc-accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}

.rc-accordion-body p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #727E8C;
}

.b-container {
  min-height: 0;
  margin-bottom: 0;
  margin-top: 60px;
  //padding: 0 15px;
  h1, h2, h3, h4, h5, h6, span, p {
    word-break: break-word;
  }
}

.zg-grid-col {
  width: 100%;
  padding: 5px 10px;

  img {
    width: 100%;
  }
}

.zg-row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);

  > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  > .zg-dragforborder {
    box-sizing: border-box;
    background-color: $background-color_1;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
}

.builder_component {
  span {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
  }

  img {
    position: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    min-height: unset !important;
    width: 100% !important;
    height: 100% !important;
    margin: unset !important;
    padding: unset !important;
  }

  a {
    img {
      cursor: pointer !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .home-product-container {
    //max-width: 900px;
  }

  .zg-col-lg {
    flex: 1 0 0;
  }
}

@media screen and (min-width: 767px) {
  .zg-col-md {
    flex: 1 0 0;
  }
}

@media screen and (max-width: 600px) {
  .zg-col-sm {
    flex: 1 0 0;
  }
}